import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { TOOLTIP_VARIANTS } from 'dpl/common/utils/constants';

const VARIANT_CLASSES = {
  [TOOLTIP_VARIANTS.LIGHT]: 'bg-white',
  [TOOLTIP_VARIANTS.DARK]: 'bg-black-70 bg-blur-2 white',
  [TOOLTIP_VARIANTS.STONE]: 'bg-stone-700 white'
};

export default function FloatingTooltip({
  title,
  minWidth,
  onMouseEnter,
  onMouseLeave,
  omitArrow,
  arrowOffset,
  setRef,
  variant
}) {
  return (
    <span
      style={{ minWidth }}
      className={classnames(
        `FloatingTooltip FloatingTooltip--${variant} br2 ph4 pv2 f2 dib`,
        VARIANT_CLASSES[variant]
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={setRef}
      role="tooltip"
    >
      {!omitArrow && (
        <span
          style={{ left: `calc(50% - ${arrowOffset}px)` }}
          className="FloatingTooltip__arrowContainer br-100 overflow-hidden pointer-events-none transform-center-x"
        >
          <span
            className={`FloatingTooltip__arrow FloatingTooltip__arrow--${variant} w0 h0 absolute`}
          />
        </span>
      )}
      {title}
    </span>
  );
}

FloatingTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  minWidth: PropTypes.string.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  omitArrow: PropTypes.bool,
  setRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  arrowOffset: PropTypes.number,
  variant: PropTypes.oneOf(Object.values(TOOLTIP_VARIANTS))
};

FloatingTooltip.defaultProps = {
  onMouseEnter: () => null,
  onMouseLeave: () => null,
  omitArrow: false,
  setRef: null,
  arrowOffset: 0,
  variant: TOOLTIP_VARIANTS.DARK
};
