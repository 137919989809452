import React from 'react';

import { isLessThanBreakpoint } from 'dpl/util/grid';

import FloatingTooltipWrapper from './FloatingTooltipWrapper';
import FixedTooltipWrapper from './FixedTooltipWrapper';

export default function TooltipWrapper(props) {
  return isLessThanBreakpoint('sm') ? (
    <FixedTooltipWrapper {...props} />
  ) : (
    <FloatingTooltipWrapper {...props} />
  );
}
