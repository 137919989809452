import React, { Component, createRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isMobileUA } from 'dpl/shared/utils';
import {
  TOOLTIP_POSITIONS,
  TOOLTIP_VARIANTS
} from 'dpl/common/utils/constants';

import FloatingTooltip from './FloatingTooltip';

export const PORTAL_MOUNT_EL = window.document.body;

export const INITIAL_POSITION = {
  top: -9999,
  left: -9999
};

const IS_MOBILE_UA = isMobileUA();

const HORIZONTAL_PADDING = 40;
const ARROW_PADDING = 12;

function getLeftOffset(tooltipEl, left, containerWidth, position) {
  const tooltipWidth = tooltipEl.clientWidth;
  const maxLeftOffset = tooltipWidth / 2 - ARROW_PADDING;
  const minLeftOffset = -maxLeftOffset;

  if (position === TOOLTIP_POSITIONS.BOTTOM_LEFT) {
    return minLeftOffset;
  }

  if (position === TOOLTIP_POSITIONS.BOTTOM_RIGHT) {
    return maxLeftOffset;
  }

  if (position === TOOLTIP_POSITIONS.BOTTOM_CENTER) {
    const rightBleed = left + tooltipWidth / 2 - containerWidth;
    const leftBleed = tooltipWidth / 2 - left;

    if (rightBleed > 0) {
      return Math.max(minLeftOffset, -(rightBleed + HORIZONTAL_PADDING));
    }

    if (leftBleed > 0) {
      return Math.min(maxLeftOffset, leftBleed + HORIZONTAL_PADDING);
    }
  }

  return 0;
}

export function getTooltipPosition(portalEl, anchorEl, tooltipEl, position) {
  const {
    top: containerTop,
    left: containerLeft,
    width: containerWidth
  } = portalEl.getBoundingClientRect();

  const {
    top: anchorTop,
    left: anchorLeft,
    width: anchorWidth,
    height: anchorHeight
  } = anchorEl.getBoundingClientRect();

  const left = anchorLeft - containerLeft;

  return {
    leftOffset: getLeftOffset(
      tooltipEl,
      left + anchorWidth / 2,
      containerWidth,
      position
    ),
    portalPosition: {
      left,
      top: anchorTop - containerTop + anchorHeight,
      width: anchorWidth
    }
  };
}

export default class FloatingTooltipWrapper extends Component {
  static propTypes = {
    buttonClassName: PropTypes.string,
    className: PropTypes.string,
    portalClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
    isVisible: PropTypes.bool,
    minWidth: PropTypes.string,
    hideDelay: PropTypes.number,
    omitArrow: PropTypes.bool,
    position: PropTypes.oneOf(Object.values(TOOLTIP_POSITIONS)),
    variant: PropTypes.oneOf(Object.values(TOOLTIP_VARIANTS)),
    disablePositionReset: PropTypes.bool
  };

  static defaultProps = {
    buttonClassName: '',
    className: '',
    portalClassName: 'z-99999',
    isVisible: false,
    position: TOOLTIP_POSITIONS.BOTTOM_CENTER,
    minWidth: '100%',
    hideDelay: 300,
    omitArrow: false,
    variant: TOOLTIP_VARIANTS.DARK,
    disablePositionReset: false
  };

  state = {
    isVisible: this.props.isVisible,
    mouseInBody: false,
    portalPosition: INITIAL_POSITION
  };

  safeSetState = this.setState;

  anchorRef = createRef();

  tooltipRef = createRef();

  handleMouseEnter = () => {
    this.setState({
      mouseInBody: true,
      isVisible: true
    });
  };

  handleMouseLeave = () => {
    this.setState({ mouseInBody: false });

    window.setTimeout(() => {
      if (!this.state.mouseInBody) {
        this.safeSetState({ isVisible: false });
      }
    }, this.props.hideDelay);
  };

  handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    const newValue = !this.state.isVisible;
    this.setState({
      isVisible: newValue,
      mouseInBody: newValue
    });
  };

  componentWillUnmount() {
    this.safeSetState = () => {};
  }

  updatePositionIfNeeded = prevState => {
    const { isVisible } = this.state;

    if (isVisible === prevState.isVisible) {
      return false;
    }

    if (!isVisible) {
      window.setTimeout(() => {
        /* disablePositionReset will make it so opening a modal from the tooltip
        will not cause the background to scroll to the top */
        if (!this.props.disablePositionReset) {
          this.safeSetState({
            portalPosition: INITIAL_POSITION
          });
        }
      }, this.props.hideDelay);

      return true;
    }

    const tooltipPortalPosition = getTooltipPosition(
      PORTAL_MOUNT_EL,
      this.anchorRef.current,
      this.tooltipRef.current,
      this.props.position
    );

    this.setState(tooltipPortalPosition);

    return true;
  };

  componentDidUpdate(prevProps, prevState) {
    this.updatePositionIfNeeded(prevState);
  }

  render() {
    const {
      leftOffset,
      isVisible,
      portalPosition: { left, top, width }
    } = this.state;

    const {
      buttonClassName,
      className,
      portalClassName,
      title,
      children,
      minWidth,
      omitArrow,
      variant
    } = this.props;

    return (
      <div className={classnames('FloatingTooltipWrapper dib', className)}>
        <div
          role="button"
          tabIndex="0"
          onMouseEnter={IS_MOBILE_UA ? null : this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onFocus={IS_MOBILE_UA ? null : this.handleMouseEnter}
          onBlur={this.handleMouseLeave}
          onClick={this.handleClick}
          onKeyPress={this.handleClick}
          className={`pointer ${buttonClassName}`}
          ref={this.anchorRef}
        >
          {children}
        </div>
        {createPortal(
          <div
            className={`${portalClassName} absolute`}
            style={{ left: `${left}px`, top: `${top}px`, width: `${width}px` }}
          >
            <div
              style={{ marginLeft: `${leftOffset}px` }}
              className={classnames('mt2 min-w-100 tc transform-center-x', {
                'pointer-events-none': !isVisible
              })}
            >
              <div
                className={classnames('transition', {
                  'FloatingTooltipWrapper__tooltip--visible': isVisible,
                  FloatingTooltipWrapper__tooltip: !isVisible
                })}
              >
                <FloatingTooltip
                  setRef={this.tooltipRef}
                  arrowOffset={leftOffset}
                  omitArrow={omitArrow}
                  title={title}
                  minWidth={minWidth}
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                  variant={variant}
                />
              </div>
            </div>
          </div>,
          PORTAL_MOUNT_EL
        )}
      </div>
    );
  }
}
