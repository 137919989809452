export const FONT_SIZE_RE = /^.f[0-9]{1,2}$/;
export const FONT_WEIGHT_RE = /^.fw[0-9]$/;
export const PADDING_RE = /^.p[t|r|b|l](-new)?[0-9]{1,2}/;
export const MARGIN_RE = /^.m[t|r|b|l](-new)?[0-9]{1,2}/;

export function getCssRules(stylesheetTitle, selectorRe) {
  const rules = {};

  [...document.styleSheets]
    .filter(f => f.title === stylesheetTitle)
    .forEach(sheet => {
      [...sheet.cssRules].forEach(rule => {
        if (selectorRe.test(rule.selectorText)) {
          rules[rule.selectorText.slice(1)] = rule.cssText;
        }
      });
    });

  return rules;
}

export const getPersistentColorForResourceId = (() => {
  const niceLookingColors = ['#0e8eff', '#34bdda', '#de4f9d', '#ff7686'];

  return resourceId => niceLookingColors[resourceId % niceLookingColors.length];
})();
