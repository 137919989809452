import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FixedTooltipPortal from './FixedTooltipPortal';

export default function FixedTooltipWrapper({
  title,
  children,
  className,
  buttonClassName,
  tooltipTextClassName
}) {
  const [isVisible, setIsVisible] = useState(false);

  function handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsVisible(!isVisible);
  }

  return (
    <div className={classnames('FixedTooltipWrapper dib', className)}>
      <div
        role="button"
        tabIndex="0"
        onKeyPress={null}
        onClick={handleClick}
        className={`pointer ${buttonClassName}`}
      >
        {children}
      </div>
      <FixedTooltipPortal
        className={tooltipTextClassName}
        isVisible={isVisible}
        onCloseClick={() => setIsVisible(false)}
      >
        {title}
      </FixedTooltipPortal>
    </div>
  );
}

FixedTooltipWrapper.propTypes = {
  buttonClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  tooltipTextClassName: PropTypes.string
};

FixedTooltipWrapper.defaultProps = {
  buttonClassName: null,
  className: null,
  tooltipTextClassName: null
};
